import * as React from "react";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import TextPage, { TextBlock } from '../components/text-page';
import commonMessageValues from '../templates/formatjs-message-values';

const CompetitiveAdvantage = () => {
  const intl = useIntl();
  const messages = intl.messages;
  const textBlocks: TextBlock[] = [{
    header: `${messages.competitive_advantages}`,
    message: (
      <>
        <FormattedMessage id="message_competitive_advantages" values={commonMessageValues}/>
        <div className="ui bulleted list">
          <div className="item"><FormattedMessage id='message_competitive_advantages_item1' values={commonMessageValues}/>
          </div>
          <div className="item">{messages.message_competitive_advantages_item2}</div>
          <div className="item">{messages.message_competitive_advantages_item3}</div>
          <div className="item">{messages.message_competitive_advantages_item4}</div>
          <div className="item">{messages.message_competitive_advantages_item5}</div>
          <div className="item">{messages.message_competitive_advantages_item6}</div>
          <div className="item">{messages.message_competitive_advantages_item7}</div>
        </div>
        <p>
          {messages.message_competitive_advantages_p2}
        </p>
      </>
    )
  }];

  return (<TextPage locale={intl.locale} title={`${messages.competitive_advantages}`} textBlocks={textBlocks}/>);
};

export default CompetitiveAdvantage;